import React, { useEffect, useState } from "react";
// import { navigate } from "gatsby";
import { Link } from "gatsby";

import Layout from "../components/Layout";

import { getEntriesByType } from "../helpers/contentfulApiCalls";
import { getMenus } from "../helpers/toastApiCalls";

import "../styles/tokens.css";
import "../styles/global.css";

import defaultImage from "../../static/images/4m-default-food-image.png";

import * as styles from "../styles/Main.module.css";
import * as menuStyles from "../styles/Menus.module.css";
import SideDrawer from "../components/SideDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookBookmark } from "@fortawesome/free-solid-svg-icons";
import { getFromLocalStorage } from "../helpers/savedItems";

const Home = ({ location }) => {
  const [menusLoaded, setMenusLoaded] = useState(false);
  const [menus, setMenus] = useState([]);
  const [contentfulFoodLines, setContentfulFoodLines] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [savedItems, setSavedItems] = useState([]);

  const url = location?.href ? location?.href : "";

  useEffect(() => {
    const localStorage = getFromLocalStorage();

    if (localStorage.ok) {
      setSavedItems(localStorage.data);
    }
  }, []);

  useEffect(() => {
    const getContentfulData = async () => {
      try {
        const foodLines = await getEntriesByType("foodLine");

        setContentfulFoodLines(foodLines);
        setMenusLoaded(true);

        return foodLines;
      } catch (error) {
        console.log("Error getting Food Lines", error);
      }
    };

    const getMenu = async () => {
      try {
        const menus = await getMenus();
        const menuArray = Object.entries(menus.menus).filter((menu) => {
          return menu[1].visibility.includes("TOAST_ONLINE_ORDERING");
        });

        const rawFoodlines = await getContentfulData();

        menuArray.forEach((menu) => {
          const foundFoodline = rawFoodlines.find((line) => {
            return line.fields.menuGuid === menu[0];
          });

          menu[1].order = foundFoodline?.fields?.order ?? 999;
        });

        menuArray.sort((a, b) => {
          return a[1].order - b[1].order;
        });

        setMenus(menuArray);
      } catch (error) {
        console.log("error getting menu", error);
      }
    };

    getMenu();
  }, []);

  const OpenDrawerButton = () => {
    let itemsText;

    if (savedItems?.length === 0) {
      itemsText = "";
    } else if (savedItems?.length <= 9) {
      itemsText = savedItems?.length;
    } else if (savedItems?.length > 9) {
      itemsText = "9+";
    } else {
      itemsText = 0;
    }

    if (savedItems && savedItems.length > 0) {
      return (
        <div
          onClick={() => {
            setSidebarOpen(true);
          }}
          className={menuStyles.drawerButton}
          title="Save for later"
        >
          <FontAwesomeIcon icon={faBookBookmark} size="xl" />
          {itemsText && (
            <div className={menuStyles.items}>
              <span>{itemsText}</span>
            </div>
          )}
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Layout url={url} name="" slug="">
      {!menusLoaded ? (
        <div className={styles.loading}></div>
      ) : (
        <SideDrawer
          isOpen={sidebarOpen}
          setOpen={setSidebarOpen}
          savedItems={savedItems}
          setSavedItems={setSavedItems}
        >
          <div className={styles.main}>
            {menus && menus.length === 0 ? (
              <p>There are no menus</p>
            ) : (
              <div className={styles.content}>
                <div className={menuStyles.menus}>
                  <div className={menuStyles.header}>
                    <Link
                      to={
                        url !== "" && !url.includes("digitalmenu")
                          ? "https://experience4m.com"
                          : "/"
                      }
                    >
                      <img
                        src="/logos/4M-Logo-VENUE_LOGO-Blue-Blk.png"
                        alt=""
                        width="200"
                      />
                    </Link>
                    {url !== "" && !url.includes("digitalmenu") ? (
                      <div>
                        <a
                          href="https://www.toasttab.com/venue-by-4m-1919-s-industrial-hwy/v3"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Order 2go
                        </a>
                        <a
                          href="https://www.exploretock.com/venue-annarbor"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Reservations
                        </a>

                        <OpenDrawerButton />
                      </div>
                    ) : (
                      <OpenDrawerButton />
                    )}
                  </div>
                  <ul>
                    {menus &&
                      menus.map((menu, index) => {
                        const contentfulData = contentfulFoodLines?.find(
                          (content) => content.fields.menuGuid === menu[1].guid
                        );
                        const marketingMsg = contentfulData?.fields?.marketing;
                        const envImage =
                          contentfulData?.fields?.environmentImage?.fields?.file
                            ?.url;

                        return (
                          <li key={menu[1].guid}>
                            <Link
                              to={menu[1].name
                                .trim()
                                .toLowerCase()
                                .replaceAll(" ", "-")}
                            >
                              <img
                                src={envImage || defaultImage}
                                alt=""
                                width="100"
                                loading="lazy"
                              />
                              <div className={menuStyles.details}>
                                <h4>{menu[1].name}</h4>
                                {marketingMsg && <p>{marketingMsg}</p>}
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </SideDrawer>
      )}
    </Layout>
  );
};

export default Home;
